<template>
    <div class="header-fill"/>
    <header>
        <nav class="wrapper">
            <div class="pushcol1 col1 icon">
                <router-link to='/'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 183.81 50.21">
                        <g id="Group_53" data-name="Group 53" transform="translate(2604.49 2058.7)">
                            <path id="Path_720" data-name="Path 720" d="M-2540.764-1901.625q-.166.823-.39,1.622c-.064.23-.131.458-.2.684-.064.208-.132.414-.2.618a23.11,23.11,0,0,1-21.848,15.577,23.111,23.111,0,0,1-21.656-15.04c-.06-.159-.117-.319-.173-.48s-.115-.337-.17-.506a22.894,22.894,0,0,1-.644-2.476,24.8,24.8,0,0,1,3.883-3.818c.114-.091.227-.179.342-.267s.237-.179.356-.267a30.417,30.417,0,0,1,18.061-5.722,30.493,30.493,0,0,1,17.7,5.459c.116.083.232.166.346.251s.226.167.336.251A24.921,24.921,0,0,1-2540.764-1901.625Z" transform="translate(-15.978 -127.37)" fill="#469ed0"/>
                            <g id="Group_49" data-name="Group 49" transform="translate(-2602.479 -2034.69)">
                            <g id="Group_47" data-name="Group 47" transform="translate(0 0)">
                                <path id="Path_721" data-name="Path 721" d="M-2546.236-1875.227l-.81-.673-.812.673c-.11.091-.223.176-.338.256a4.885,4.885,0,0,1-3.778.76,4.883,4.883,0,0,1-2.1-1.016l-.812-.673-.809.673a4.88,4.88,0,0,1-6.223,0l-.813-.673-.809.673a4.88,4.88,0,0,1-6.223,0l-.437-.363-.373-.31-.812.673a4.876,4.876,0,0,1-6.22,0l-.813-.673-.809.673a4.877,4.877,0,0,1-5.427.536,4.743,4.743,0,0,1-.8-.536l-.809-.673-.812.673a4.85,4.85,0,0,1-2.244,1.043c.005.135.012.269.021.4a22.79,22.79,0,0,0,.415,3.175,22.843,22.843,0,0,0,.644,2.476c.054.17.112.338.169.506s.114.322.174.481a23.11,23.11,0,0,0,21.656,15.04,23.11,23.11,0,0,0,21.848-15.577c.071-.2.139-.41.2-.618.072-.226.139-.454.2-.684q.224-.8.39-1.622a23,23,0,0,0,.41-3.1c.01-.132.016-.266.021-.4A4.86,4.86,0,0,1-2546.236-1875.227Z" transform="translate(2589.335 1876.301)" fill="#00325f"/>
                                <path id="Path_722" data-name="Path 722" d="M-2546.076-1878.135l-.809-.672a.4.4,0,0,0-.511,0l-.812.673a4.5,4.5,0,0,1-.429.314,4.461,4.461,0,0,1-3.349.618,4.474,4.474,0,0,1-1.932-.934l-.812-.672a.4.4,0,0,0-.511,0l-.809.67a4.478,4.478,0,0,1-5.711,0l-.812-.672a.4.4,0,0,0-.511,0l-.81.672a4.484,4.484,0,0,1-5.711,0l-.811-.673a.4.4,0,0,0-.51,0l-.813.673a4.476,4.476,0,0,1-5.709,0l-.812-.672a.4.4,0,0,0-.512,0l-.809.672a4.486,4.486,0,0,1-4.815.577,4.506,4.506,0,0,1-.9-.577l-.809-.672a.4.4,0,0,0-.512,0l-.813.673a4.426,4.426,0,0,1-2,.944c0,.136.008.272.015.407s.012.269.021.4a5.252,5.252,0,0,0,2.478-1.136l.557-.461.554.461a5.247,5.247,0,0,0,.709.494,5.286,5.286,0,0,0,6.025-.5l.554-.459.556.459a5.278,5.278,0,0,0,6.731,0l.557-.461.554.461a5.285,5.285,0,0,0,6.734,0l.554-.459.555.459a5.283,5.283,0,0,0,6.734,0l.554-.459.557.461a5.278,5.278,0,0,0,2.278,1.1,5.251,5.251,0,0,0,4.2-.9c.087-.063.172-.13.257-.2l.557-.461.554.461a5.271,5.271,0,0,0,3.3,1.21c.01-.132.016-.266.021-.4s.012-.267.016-.4A4.469,4.469,0,0,1-2546.076-1878.135Z" transform="translate(2589.43 1878.9)" fill="#00325f"/>
                            </g>
                            <g id="Group_48" data-name="Group 48" transform="translate(1.095 6.794)">
                                <path id="Path_723" data-name="Path 723" d="M-2542.135-1824.347l-.809-.673-.812.673a4.887,4.887,0,0,1-4.631.879,4.829,4.829,0,0,1-1.592-.879l-.809-.673-.813.673a4.875,4.875,0,0,1-6.22,0l-.812-.673-.81.673a4.879,4.879,0,0,1-6.222,0l-.437-.363-.373-.31-.813.673a4.876,4.876,0,0,1-6.22,0l-.812-.673-.809.673a4.879,4.879,0,0,1-4.831.808q.084.242.174.481a23.11,23.11,0,0,0,21.656,15.04,23.11,23.11,0,0,0,21.848-15.577c.071-.2.139-.41.2-.618A4.879,4.879,0,0,1-2542.135-1824.347Z" transform="translate(2580.13 1825.421)" fill="#072341"/>
                                <path id="Path_724" data-name="Path 724" d="M-2537.268-1827.256a4.484,4.484,0,0,1-5.711,0l-.81-.672a.4.4,0,0,0-.511,0l-.813.673a4.46,4.46,0,0,1-4.252.805,4.4,4.4,0,0,1-1.458-.805l-.81-.672a.4.4,0,0,0-.511,0l-.812.673a4.477,4.477,0,0,1-5.709,0l-.813-.672a.4.4,0,0,0-.51,0l-.81.67a4.484,4.484,0,0,1-5.711,0l-.811-.673a.4.4,0,0,0-.511,0l-.812.673a4.477,4.477,0,0,1-5.709,0l-.813-.672a.4.4,0,0,0-.51,0l-.809.67a4.49,4.49,0,0,1-4.746.612c.054.17.112.338.169.506s.114.322.174.481a5.284,5.284,0,0,0,4.913-.982l.555-.459.555.461a5.282,5.282,0,0,0,6.732,0l.555-.461.555.461a5.283,5.283,0,0,0,6.733,0l.554-.459.557.461a5.281,5.281,0,0,0,6.731,0l.555-.461.554.459a5.2,5.2,0,0,0,1.724.952,5.258,5.258,0,0,0,5.01-.951l.557-.461.554.461a5.283,5.283,0,0,0,6.107.443q.106-.307.2-.618c.072-.226.139-.454.2-.684Z" transform="translate(2581.23 1828.02)" fill="#072341"/>
                            </g>
                            </g>
                            <path id="Path_725" data-name="Path 725" d="M-2579.385-2056.7a23.1,23.1,0,0,1,23.1,23.1c0,.234,0,.466-.011.7q-.006.2-.016.4c-.005.133-.012.267-.021.4a23.052,23.052,0,0,1-.41,3.1q-.166.823-.39,1.622c-.064.229-.131.458-.2.684-.064.208-.133.414-.2.618a23.11,23.11,0,0,1-21.848,15.577,23.11,23.11,0,0,1-21.656-15.04q-.089-.238-.173-.481c-.058-.168-.115-.336-.169-.506a22.7,22.7,0,0,1-.644-2.476,22.791,22.791,0,0,1-.416-3.175c-.009-.134-.016-.267-.021-.4s-.011-.271-.015-.407q-.008-.306-.008-.614a23.1,23.1,0,0,1,23.1-23.1m0-2a24.948,24.948,0,0,0-9.772,1.973,25.019,25.019,0,0,0-7.979,5.379,25.016,25.016,0,0,0-5.38,7.979,24.952,24.952,0,0,0-1.974,9.773c0,.222,0,.446.009.667,0,.147.009.292.016.445s.014.307.024.443a24.82,24.82,0,0,0,.451,3.445,24.953,24.953,0,0,0,.7,2.688c.061.188.121.366.18.539l0,.005,0,.007c.067.193.13.366.191.528a25.05,25.05,0,0,0,9.052,11.745,24.942,24.942,0,0,0,14.479,4.594,25.113,25.113,0,0,0,23.742-16.928c.082-.236.154-.457.221-.671s.143-.467.221-.744c.16-.569.3-1.164.424-1.764a25.07,25.07,0,0,0,.445-3.366c.009-.134.017-.28.024-.444q.011-.215.017-.43c.008-.27.012-.517.012-.757a24.952,24.952,0,0,0-1.974-9.773,25.019,25.019,0,0,0-5.379-7.979,25.019,25.019,0,0,0-7.979-5.379,24.949,24.949,0,0,0-9.773-1.973Z" fill="#072341"/>
                            <g id="Group_50" data-name="Group 50" transform="translate(-2550.663 -2043.899)">
                            <path id="Path_726" data-name="Path 726" d="M-2201.4-1947.86h2.4l2.737,7.59,2.722-7.59h2.4l-3.8,9.869h-2.633Z" transform="translate(2201.399 1947.86)" fill="#072341"/>
                            <path id="Path_727" data-name="Path 727" d="M-2124.468-1928.8c2.131,0,3.581,1.6,3.581,3.936v.415h-5.312a1.926,1.926,0,0,0,2.056,1.657,2.922,2.922,0,0,0,1.909-.71l.829,1.213a4.454,4.454,0,0,1-2.944.991,3.619,3.619,0,0,1-3.817-3.758A3.637,3.637,0,0,1-2124.468-1928.8Zm-1.761,3.078h3.521a1.655,1.655,0,0,0-1.761-1.583A1.718,1.718,0,0,0-2126.229-1925.725Z" transform="translate(2137.946 1931.348)" fill="#072341"/>
                            <path id="Path_728" data-name="Path 728" d="M-2067.305-1923.728a4.091,4.091,0,0,0,2.471.977c.814,0,1.2-.281,1.2-.725,0-.5-.622-.68-1.436-.843-1.228-.237-2.811-.533-2.811-2.234,0-1.2,1.051-2.249,2.93-2.249a4.566,4.566,0,0,1,2.885.977l-.754,1.287a3.014,3.014,0,0,0-2.116-.829c-.68,0-1.124.251-1.124.666,0,.444.562.607,1.361.769,1.228.237,2.87.562,2.87,2.338,0,1.3-1.109,2.293-3.092,2.293a4.812,4.812,0,0,1-3.2-1.11Z" transform="translate(2085.917 1931.348)" fill="#072341"/>
                            <path id="Path_729" data-name="Path 729" d="M-2015.53-1934.775v-3.728h-1.184v-1.642h1.184v-1.953h1.894v1.953h1.45v1.642h-1.45v3.225c0,.444.237.784.651.784a1.025,1.025,0,0,0,.651-.207l.4,1.42a2.319,2.319,0,0,1-1.568.459A1.786,1.786,0,0,1-2015.53-1934.775Z" transform="translate(2041.376 1942.868)" fill="#072341"/>
                            <path id="Path_730" data-name="Path 730" d="M-1972.908-1928.8c2.131,0,3.58,1.6,3.58,3.936v.415h-5.312a1.926,1.926,0,0,0,2.057,1.657,2.922,2.922,0,0,0,1.909-.71l.828,1.213a4.453,4.453,0,0,1-2.944.991,3.619,3.619,0,0,1-3.817-3.758A3.637,3.637,0,0,1-1972.908-1928.8Zm-1.761,3.078h3.521a1.655,1.655,0,0,0-1.76-1.583A1.717,1.717,0,0,0-1974.669-1925.725Z" transform="translate(2006.625 1931.348)" fill="#072341"/>
                            <path id="Path_731" data-name="Path 731" d="M-1911.9-1928.53h1.879v.977a3.058,3.058,0,0,1,2.279-1.139v1.834a2.385,2.385,0,0,0-.518-.059,2.4,2.4,0,0,0-1.761.814v4.72h-1.879Z" transform="translate(1950.563 1931.252)" fill="#072341"/>
                            <path id="Path_732" data-name="Path 732" d="M-1867.184-1942.341c0-.991-.518-1.3-1.332-1.3a2.082,2.082,0,0,0-1.628.844v4.809h-1.879v-9.869h1.879v3.654a3.307,3.307,0,0,1,2.515-1.109,2.057,2.057,0,0,1,2.323,2.249v5.075h-1.879Z" transform="translate(1916.006 1947.86)" fill="#072341"/>
                            <path id="Path_733" data-name="Path 733" d="M-1806.45-1922.219a2.957,2.957,0,0,1-2.264.917,2.381,2.381,0,0,1-2.486-2.367,2.263,2.263,0,0,1,2.486-2.279,2.888,2.888,0,0,1,2.264.873v-.991c0-.725-.622-1.2-1.569-1.2a3.006,3.006,0,0,0-2.086.844l-.71-1.258a4.687,4.687,0,0,1,3.122-1.125c1.628,0,3.122.651,3.122,2.708v4.616h-1.879Zm0-1.864a1.88,1.88,0,0,0-1.509-.622c-.74,0-1.346.385-1.346,1.08,0,.666.607,1.051,1.346,1.051a1.881,1.881,0,0,0,1.509-.621Z" transform="translate(1863.305 1931.348)" fill="#072341"/>
                            <path id="Path_734" data-name="Path 734" d="M-1755.25-1927.473h2.012l1.864,4.971,1.865-4.971h2.027l-2.87,7.146h-2.027Z" transform="translate(1814.827 1930.195)" fill="#072341"/>
                            <path id="Path_735" data-name="Path 735" d="M-1694.611-1923.728a4.091,4.091,0,0,0,2.471.977c.814,0,1.2-.281,1.2-.725,0-.5-.621-.68-1.435-.843-1.228-.237-2.811-.533-2.811-2.234,0-1.2,1.051-2.249,2.93-2.249a4.565,4.565,0,0,1,2.885.977l-.754,1.287a3.015,3.015,0,0,0-2.116-.829c-.681,0-1.125.251-1.125.666,0,.444.562.607,1.361.769,1.228.237,2.87.562,2.87,2.338,0,1.3-1.11,2.293-3.092,2.293a4.811,4.811,0,0,1-3.2-1.11Z" transform="translate(1762.991 1931.348)" fill="#072341"/>
                            <path id="Path_736" data-name="Path 736" d="M-1640.205-1921.006a2.84,2.84,0,0,0,2.234.888,1.747,1.747,0,0,0,1.968-1.8v-.725a2.782,2.782,0,0,1-2.2,1.125c-1.79,0-3.152-1.258-3.152-3.64,0-2.338,1.346-3.64,3.152-3.64a2.752,2.752,0,0,1,2.2,1.095v-.917h1.894v6.658c0,2.722-2.1,3.388-3.862,3.388a4.33,4.33,0,0,1-3.078-1.065Zm4.2-5.312a2.037,2.037,0,0,0-1.613-.814,1.772,1.772,0,0,0-1.805,1.968,1.772,1.772,0,0,0,1.805,1.968,2.081,2.081,0,0,0,1.613-.814Z" transform="translate(1716.144 1931.348)" fill="#072341"/>
                            <path id="Path_737" data-name="Path 737" d="M-1573.113-1928.53h1.879v.977a3.058,3.058,0,0,1,2.279-1.139v1.834a2.386,2.386,0,0,0-.518-.059,2.394,2.394,0,0,0-1.761.814v4.72h-1.879Z" transform="translate(1657.012 1931.252)" fill="#072341"/>
                            <path id="Path_738" data-name="Path 738" d="M-1528.406-1921.229a3.345,3.345,0,0,1-2.515,1.08,2.047,2.047,0,0,1-2.308-2.249v-5.074h1.879v4.335a1.149,1.149,0,0,0,1.317,1.317,2.1,2.1,0,0,0,1.627-.814v-4.838h1.879v7.146h-1.879Z" transform="translate(1622.453 1930.195)" fill="#072341"/>
                            <path id="Path_739" data-name="Path 739" d="M-1469.082-1918.757v-9.868h1.879v.9a2.761,2.761,0,0,1,2.2-1.08c1.835,0,3.166,1.361,3.166,3.743s-1.331,3.758-3.166,3.758a2.731,2.731,0,0,1-2.2-1.095v3.639Zm3.492-8.374a2.1,2.1,0,0,0-1.613.814v2.53a2.15,2.15,0,0,0,1.613.814,1.858,1.858,0,0,0,1.8-2.086A1.853,1.853,0,0,0-1465.59-1927.131Z" transform="translate(1566.872 1931.348)" fill="#072341"/>
                            <path id="Path_740" data-name="Path 740" d="M-1404.27-1918.757v-9.868h1.879v.9a2.76,2.76,0,0,1,2.2-1.08c1.835,0,3.166,1.361,3.166,3.743s-1.331,3.758-3.166,3.758a2.731,2.731,0,0,1-2.2-1.095v3.639Zm3.492-8.374a2.105,2.105,0,0,0-1.613.814v2.53a2.151,2.151,0,0,0,1.613.814,1.858,1.858,0,0,0,1.8-2.086A1.853,1.853,0,0,0-1400.778-1927.131Z" transform="translate(1510.715 1931.348)" fill="#072341"/>
                            <path id="Path_741" data-name="Path 741" d="M-1339.193-1928.8c2.13,0,3.581,1.6,3.581,3.936v.415h-5.312a1.926,1.926,0,0,0,2.057,1.657,2.922,2.922,0,0,0,1.908-.71l.828,1.213a4.453,4.453,0,0,1-2.944.991,3.619,3.619,0,0,1-3.817-3.758A3.637,3.637,0,0,1-1339.193-1928.8Zm-1.761,3.078h3.521a1.655,1.655,0,0,0-1.76-1.583A1.718,1.718,0,0,0-1340.954-1925.725Z" transform="translate(1457.533 1931.348)" fill="#072341"/>
                            <path id="Path_742" data-name="Path 742" d="M-1273.369-1925.8a1.16,1.16,0,0,0-1.317-1.332,2.035,2.035,0,0,0-1.628.844v4.809h-1.879v-7.146h1.879v.932a3.272,3.272,0,0,1,2.5-1.11,2.079,2.079,0,0,1,2.323,2.279v5.045h-1.879Z" transform="translate(1401.473 1931.348)" fill="#072341"/>
                            </g>
                            <g id="Group_51" data-name="Group 51" transform="translate(-2548.836 -2030.801)">
                            <path id="Path_743" data-name="Path 743" d="M-2187.717-1843.709h2.53a3.6,3.6,0,0,1,3.794,3.706,3.586,3.586,0,0,1-3.794,3.695h-2.53Zm2.53,6.58a2.721,2.721,0,0,0,2.84-2.874,2.706,2.706,0,0,0-2.84-2.885h-1.609v5.759Z" transform="translate(2187.717 1844.519)" fill="#072341"/>
                            <path id="Path_744" data-name="Path 744" d="M-2129.486-1829.418a2.6,2.6,0,0,1,2.574,2.874v.211h-4.338a1.889,1.889,0,0,0,1.931,1.853,2.342,2.342,0,0,0,1.653-.677l.4.544a2.911,2.911,0,0,1-2.131.821,2.657,2.657,0,0,1-2.729-2.818A2.68,2.68,0,0,1-2129.486-1829.418Zm-1.764,2.474h3.517a1.737,1.737,0,0,0-1.765-1.786A1.788,1.788,0,0,0-2131.25-1826.944Z" transform="translate(2139.55 1832.136)" fill="#072341"/>
                            <path id="Path_745" data-name="Path 745" d="M-2061.582-1843.709h.832v2.852a2.225,2.225,0,0,1,1.809-.943c1.4,0,2.386,1.109,2.386,2.818,0,1.742-.988,2.807-2.386,2.807a2.257,2.257,0,0,1-1.809-.932v.8h-.832Zm2.43,6.79c1.087,0,1.731-.877,1.731-2.064s-.644-2.075-1.731-2.075a1.991,1.991,0,0,0-1.6.865v2.43A1.972,1.972,0,0,0-2059.152-1836.919Z" transform="translate(2078.426 1844.519)" fill="#072341"/>
                            <path id="Path_746" data-name="Path 746" d="M-2013.8-1843.709h.832v7.4h-.832Z" transform="translate(2037.027 1844.519)" fill="#072341"/>
                            <path id="Path_747" data-name="Path 747" d="M-1993.7-1842.175a2.318,2.318,0,0,1-1.775.744,1.785,1.785,0,0,1-1.875-1.775,1.755,1.755,0,0,1,1.875-1.764,2.241,2.241,0,0,1,1.775.732v-.966c0-.721-.577-1.131-1.354-1.131a2.129,2.129,0,0,0-1.642.743l-.388-.577a2.806,2.806,0,0,1,2.141-.887c1.132,0,2.075.51,2.075,1.809v3.684h-.832Zm0-1.531a1.8,1.8,0,0,0-1.476-.666,1.2,1.2,0,0,0-1.32,1.176,1.2,1.2,0,0,0,1.32,1.165,1.794,1.794,0,0,0,1.476-.666Zm-1.232-6.069a1.1,1.1,0,0,1,1.087,1.1,1.089,1.089,0,0,1-1.087,1.087,1.089,1.089,0,0,1-1.087-1.087A1.1,1.1,0,0,1-1994.932-1849.775Zm0,.444a.647.647,0,0,0-.644.655.654.654,0,0,0,.644.655.646.646,0,0,0,.644-.655A.64.64,0,0,0-1994.932-1849.332Z" transform="translate(2022.772 1849.775)" fill="#072341"/>
                            <path id="Path_748" data-name="Path 748" d="M-1932.937-1825.235a2.429,2.429,0,0,0,1.764.765c.821,0,1.265-.366,1.265-.887,0-.588-.655-.777-1.376-.954-.91-.211-1.942-.455-1.942-1.564,0-.832.721-1.542,2-1.542a2.711,2.711,0,0,1,1.986.777l-.388.577a2.089,2.089,0,0,0-1.6-.677c-.732,0-1.187.333-1.187.81,0,.521.61.688,1.309.854.932.211,2.008.477,2.008,1.664,0,.9-.721,1.62-2.108,1.62a2.851,2.851,0,0,1-2.164-.843Z" transform="translate(1967.334 1832.136)" fill="#072341"/>
                            <path id="Path_749" data-name="Path 749" d="M-1891.243-1821.884v-7.4h.832v.8a2.241,2.241,0,0,1,1.809-.932c1.409,0,2.385,1.065,2.385,2.807s-.977,2.818-2.385,2.818a2.2,2.2,0,0,1-1.809-.943v2.852Zm2.43-6.791a1.982,1.982,0,0,0-1.6.854v2.419a1.991,1.991,0,0,0,1.6.865c1.076,0,1.72-.887,1.72-2.075S-1887.737-1828.675-1888.813-1828.675Z" transform="translate(1930.833 1832.136)" fill="#072341"/>
                            <path id="Path_750" data-name="Path 750" d="M-1843.233-1829.418a2.6,2.6,0,0,1,2.574,2.874v.211H-1845a1.888,1.888,0,0,0,1.931,1.853,2.343,2.343,0,0,0,1.653-.677l.4.544a2.91,2.91,0,0,1-2.13.821,2.657,2.657,0,0,1-2.729-2.818A2.68,2.68,0,0,1-1843.233-1829.418Zm-1.764,2.474h3.517a1.737,1.737,0,0,0-1.764-1.786A1.788,1.788,0,0,0-1845-1826.944Z" transform="translate(1891.522 1832.136)" fill="#072341"/>
                            <path id="Path_751" data-name="Path 751" d="M-1804.646-1835.643a1.465,1.465,0,0,1-1.576,1.653,1.962,1.962,0,0,1-.6-.078l.111-.7a1.159,1.159,0,0,0,.4.056c.488,0,.832-.255.832-.932v-5.881h.832Zm-.976-7.345a.556.556,0,0,1,.555-.555.559.559,0,0,1,.566.555.568.568,0,0,1-.566.566A.566.566,0,0,1-1805.623-1842.988Z" transform="translate(1857.684 1844.375)" fill="#072341"/>
                            <path id="Path_752" data-name="Path 752" d="M-1776.2-1837.107a2.256,2.256,0,0,1-1.808.932c-1.4,0-2.385-1.065-2.385-2.807,0-1.709.976-2.818,2.385-2.818a2.24,2.24,0,0,1,1.808.943v-2.852h.832v7.4h-.832Zm0-3.085a2.012,2.012,0,0,0-1.609-.865c-1.076,0-1.72.887-1.72,2.075s.644,2.064,1.72,2.064a1.993,1.993,0,0,0,1.609-.843Z" transform="translate(1834.79 1844.519)" fill="#072341"/>
                            <path id="Path_753" data-name="Path 753" d="M-1729.979-1829.418a2.6,2.6,0,0,1,2.574,2.874v.211h-4.338a1.888,1.888,0,0,0,1.93,1.853,2.343,2.343,0,0,0,1.653-.677l.4.544a2.911,2.911,0,0,1-2.13.821,2.657,2.657,0,0,1-2.729-2.818A2.68,2.68,0,0,1-1729.979-1829.418Zm-1.764,2.474h3.517a1.737,1.737,0,0,0-1.764-1.786A1.788,1.788,0,0,0-1731.742-1826.944Z" transform="translate(1793.391 1832.136)" fill="#072341"/>
                            <path id="Path_754" data-name="Path 754" d="M-1683.512-1829.141h.832v.865a2.278,2.278,0,0,1,1.786-.977v.854a1.465,1.465,0,0,0-.333-.033,1.96,1.96,0,0,0-1.454.854v3.795h-.832Z" transform="translate(1750.841 1831.993)" fill="#072341"/>
                            <path id="Path_755" data-name="Path 755" d="M-1655.778-1829.418a2.6,2.6,0,0,1,2.574,2.874v.211h-4.338a1.888,1.888,0,0,0,1.931,1.853,2.342,2.342,0,0,0,1.653-.677l.4.544a2.911,2.911,0,0,1-2.13.821,2.657,2.657,0,0,1-2.729-2.818A2.68,2.68,0,0,1-1655.778-1829.418Zm-1.764,2.474h3.517a1.737,1.737,0,0,0-1.764-1.786A1.788,1.788,0,0,0-1657.542-1826.944Z" transform="translate(1729.099 1832.136)" fill="#072341"/>
                            <path id="Path_756" data-name="Path 756" d="M-1588.954-1842.988a.556.556,0,0,1,.555-.555.559.559,0,0,1,.566.555.568.568,0,0,1-.566.566A.566.566,0,0,1-1588.954-1842.988Zm.144,1.464h.832v5.359h-.832Z" transform="translate(1668.91 1844.375)" fill="#072341"/>
                            <path id="Path_757" data-name="Path 757" d="M-1547.492-1843.709h4.849v.821h-3.928v2.385h3.85v.821h-3.85v2.552h3.928v.821h-4.849Z" transform="translate(1632.985 1844.519)" fill="#072341"/>
                            <path id="Path_758" data-name="Path 758" d="M-1503.519-1825.235a2.428,2.428,0,0,0,1.764.765c.821,0,1.265-.366,1.265-.887,0-.588-.655-.777-1.376-.954-.91-.211-1.942-.455-1.942-1.564,0-.832.721-1.542,2-1.542a2.711,2.711,0,0,1,1.986.777l-.388.577a2.088,2.088,0,0,0-1.6-.677c-.732,0-1.187.333-1.187.81,0,.521.61.688,1.309.854.932.211,2.008.477,2.008,1.664,0,.9-.721,1.62-2.108,1.62a2.851,2.851,0,0,1-2.164-.843Z" transform="translate(1595.259 1832.136)" fill="#072341"/>
                            <path id="Path_759" data-name="Path 759" d="M-1461.824-1843.709h.832v2.852a2.225,2.225,0,0,1,1.808-.943c1.4,0,2.385,1.109,2.385,2.818,0,1.742-.987,2.807-2.385,2.807a2.257,2.257,0,0,1-1.808-.932v.8h-.832Zm2.43,6.79c1.087,0,1.731-.877,1.731-2.064s-.644-2.075-1.731-2.075a1.991,1.991,0,0,0-1.6.865v2.43A1.972,1.972,0,0,0-1459.394-1836.919Z" transform="translate(1558.756 1844.519)" fill="#072341"/>
                            <path id="Path_760" data-name="Path 760" d="M-1421.925-1835.643a1.465,1.465,0,0,1-1.575,1.653,1.965,1.965,0,0,1-.6-.078l.111-.7a1.159,1.159,0,0,0,.4.056c.488,0,.832-.255.832-.932v-5.881h.832Zm-.976-7.345a.556.556,0,0,1,.555-.555.559.559,0,0,1,.566.555.568.568,0,0,1-.566.566A.566.566,0,0,1-1422.9-1842.988Z" transform="translate(1526.069 1844.375)" fill="#072341"/>
                            <path id="Path_761" data-name="Path 761" d="M-1395.035-1829.418a2.6,2.6,0,0,1,2.574,2.874v.211h-4.339a1.889,1.889,0,0,0,1.931,1.853,2.342,2.342,0,0,0,1.653-.677l.4.544a2.911,2.911,0,0,1-2.13.821,2.657,2.657,0,0,1-2.729-2.818A2.68,2.68,0,0,1-1395.035-1829.418Zm-1.764,2.474h3.517a1.737,1.737,0,0,0-1.764-1.786A1.788,1.788,0,0,0-1396.8-1826.944Z" transform="translate(1503.175 1832.136)" fill="#072341"/>
                            <path id="Path_762" data-name="Path 762" d="M-1348.569-1829.141h.832v.865a2.278,2.278,0,0,1,1.786-.977v.854a1.467,1.467,0,0,0-.333-.033,1.96,1.96,0,0,0-1.453.854v3.795h-.832Z" transform="translate(1460.625 1831.993)" fill="#072341"/>
                            <path id="Path_763" data-name="Path 763" d="M-1322.755-1823.171a2.046,2.046,0,0,0,1.742.732,1.535,1.535,0,0,0,1.72-1.609v-.766a2.238,2.238,0,0,1-1.8.954c-1.4,0-2.386-1.043-2.386-2.774s.976-2.785,2.386-2.785a2.22,2.22,0,0,1,1.8.943v-.81h.832v5.2c0,1.775-1.243,2.33-2.552,2.33a2.739,2.739,0,0,1-2.164-.8Zm3.462-4.638a1.975,1.975,0,0,0-1.6-.865c-1.076,0-1.72.854-1.72,2.042s.644,2.042,1.72,2.042a1.984,1.984,0,0,0,1.6-.876Z" transform="translate(1438.883 1832.136)" fill="#072341"/>
                            </g>
                        </g>
                    </svg>
                </router-link>
            </div>
            <div class="menuItems">
                <router-link to='/afdelinger'>Afdelinger</router-link>
                <router-link to='/omos'>Om os</router-link>
                <router-link to='/praktisk'>Praktisk</router-link>
                <router-link to='/kalender'>Kalender</router-link>
                <a href="https://www.facebook.com/Vesterhavsgruppen-DDS-908378059235877/photos/?tab=albums&ref=page_internal" target="_blanc">Galleri</a>
                <div class="signup">
                    <router-link to='/tilmelding'>Bliv Spejder</router-link>
                </div>
            </div>
        </nav>
        <div class="stripes"/>
    </header>
</template>

<style lang="scss" scoped>
    .header-fill{
        height: 80px;
    }
    header{
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        font-size: 13px;
        font-family: proxima-nova, sans-serif;
        font-weight: 700;
        font-style: normal;
        width: 100%;
        height: 80px;
        background: var(--whiteshade1);
        > nav{
            display: flex;
            position: relative;
            height: 70px;
            align-items: center;
            a{
                text-decoration: none;
                color: var(--black);
                font-family: proxima-nova, sans-serif;
                font-weight: 700;
                font-style: normal;
                font-size: 13px;
            }
            > .icon{
                display: flex;
                align-items: center;
                padding-right: .2em;
                display: block;
            }
            > .menuItems{
                position: absolute;
                right: calc(8.333%);
                display: flex;
                align-items: center;
                > a {
                    display: block;
                    width: 67px;
                    text-align: center;
                    margin-right: 6px;
                }
                > .signup{
                    display: flex;
                    justify-content: right;
                    > a{
                        width: 120px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: var(--prim);
                        color: var(--whiteshade1);
                        border-radius: 10px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 800px) {
        header, .header-fill, nav{
            height: auto !important;
            position: static;
        }
        .wrapper{
            display: block !important;
        }
        a{
            display: block;
            margin: 20px auto !important;
            font-size: 32px !important;
            width: auto !important;
        }
        .menuItems{
            position: static !important;
            display: block !important;
        }

        .signup{
            justify-content: center !important;
            a{
                height: auto !important;
                padding: 4px 20px;
            }
        }
    }
</style>